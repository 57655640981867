import { Injectable } from '@angular/core';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import "rxjs/add/operator/do";
import { apiUrl, getAuthHeaders } from '../constants';
import { Http,Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import Product from '../models/product';

@Injectable()
export class KorpaService {

  protected urladd = apiUrl + 'korpaaddservice.php';
  protected urlremove = apiUrl + 'korparemoveservice.php';
  protected urlupdate = apiUrl + 'korpaupdateservice.php';
  protected urlcheckout = apiUrl + 'korpacheckoutservice.php';
  protected urlget = apiUrl + 'korpagetservice.php';
  brojPorudzbinaUKorpi = [];
  prKorpa: Product[];
  kolicina: number[] = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30, 40, 50 ];

  constructor(protected http: Http) { }

  addToCart(product_id: number, quantity: number) {
    let data = "product_id=" + product_id + "&quantity=" + quantity;
    let headers = getAuthHeaders();
    this.http.post(this.urladd, data, { headers: headers })
      .map(res => res)
      .subscribe(data => {
        this.getCart();
      });
}

removeFromCart(product_id: number) {
  let data = "product_id=" + product_id;
  let headers = getAuthHeaders();
  this.http.post(this.urlremove, data, {headers: headers})
      .map(res => res)
    .subscribe(data => {
      if (this.brojPorudzbinaUKorpi[0]-- < 2) this.brojPorudzbinaUKorpi[0] = null;
      this.prKorpa.splice(0, 1);
    });
}

removeFromCartToSubscribe(product_id: number) {
  let data = "product_id=" + product_id;
  let headers = getAuthHeaders();
  return this.http.post(this.urlremove, data, {headers: headers})
      .map(res => res);
}

updateInCart(product_id: number, quantity: number) {
  let data = "product_id=" + product_id + "&quantity=" + quantity;
  let headers = getAuthHeaders();
  this.http.post(this.urlupdate, data, { headers: headers })
    .map(res => res)
    .subscribe(data => data);
}

checkout() {
  let headers = getAuthHeaders();
  this.http.get(this.urlcheckout, { headers: headers })
    .map(res => res)
    .subscribe(data => data);
}

getCart() {
  let headers = getAuthHeaders();
  this.http.get(this.urlget, { headers: headers })
    .map((response: Response) => <Product[]>response.json())
    .subscribe(data => {
      this.prKorpa = data;
      this.brojPorudzbinaUKorpi[0] = data.length == 0 ? null : data.length;
    });;
}
}
